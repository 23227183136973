import React, { useEffect } from "react";
import Hero from "./Hero";
import { useLocation } from "react-router-dom";
// import Conatct from "./Contact";
const PrivacyPolicy = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/privacy-policy") {
      const element = document.getElementById("privacy-policy");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  return (
    <main className="main">
      {/*<Hero />*/}
      <section
        id="privacy-policy"
        className="services section"
        style={{ textAlign: "justify" }}
      >
        <div className="container section-title" data-aos="fade-up">
          <h2>Privacy Policy</h2>
          <p>Privacy Policy for Eyedyll Application</p>
        </div>

        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
              <p>
                This Privacy Policy applies between you, the User of this
                Website, and Egghead Partners Limited, the owner and provider of
                this Website. Egghead Partners Limited takes the privacy of your
                information very seriously. This Privacy Policy applies to our
                use of any and all Data collected by us or provided by you in
                relation to your use of the Website.
              </p>
              <p>
                This Privacy Policy should be read alongside, and in addition
                to, our Terms and Conditions, which can be found at:{" "}
                <a href="https://www.eyedyll.com/privacy-policy">
                  https://www.eyedyll.com/privacy-policy
                </a>
              </p>
              <p>Please read this Privacy Policy carefully.</p>
              <div>
                <table className="table table-bordered table-responsive">
                  <thead>
                    <th>Term</th>
                    <th>Definition</th>
                  </thead>
                  <tr>
                    <td>Data</td>
                    <td>
                      collectively all information that you submit to Egghead
                      Partners Limited via the Website. This definition
                      incorporates, where applicable, the definitions provided
                      in the Data Protection Laws;
                    </td>
                  </tr>
                  <tr>
                    <td>Data Protection Laws</td>
                    <td>
                      any applicable law relating to the processing of personal
                      Data, including but not limited to the GDPR, and any
                      national implementing and supplementary laws, regulations
                      and secondary legislation;
                    </td>
                  </tr>
                  <tr>
                    <td>GDPR</td>
                    <td>the UK General Data Protection Regulation;</td>
                  </tr>
                  <tr>
                    <td>Egghead Partners Limited, we or us</td>
                    <td>
                      Egghead Partners Limited, a company incorporated in
                      England and Wales with registered number 13005074 whose
                      registered office is at 52 Village Way, Pinner, HA5 5AF;
                    </td>
                  </tr>
                  <tr>
                    <td>User or you</td>
                    <td>
                      any third party that accesses the Website and is not
                      either (i) employed by Egghead Partners Limited and acting
                      in the course of their employment or (ii) engaged as a
                      consultant or otherwise providing services to Egghead
                      Partners Limited and accessing the Website in connection
                      with the provision of such services;
                    </td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>
                      the website that you are currently using, www.eyedyll.com,
                      and any sub-domains of this site unless expressly excluded
                      by their own terms and conditions.
                    </td>
                  </tr>
                </table>
              </div>
              <p>
                2. In this Privacy Policy, unless the context requires a
                different interpretation:
              </p>
              <div>
                <ul>
                  <li>The singular includes the plural and vice versa;</li>
                  <li>
                    References to sub-clauses, clauses, schedules or appendices
                    are to sub-clauses, clauses, schedules or appendices of this
                    Privacy Policy;
                  </li>
                  <li>
                    A reference to a person includes firms, companies,
                    government entities, trusts and partnerships;
                  </li>
                  <li>
                    "including" is understood to mean "including without
                    limitation";
                  </li>
                  <li>
                    Reference to any statutory provision includes any
                    modification or amendment of it;
                  </li>
                  <li>
                    The headings and sub-headings do not form part of this
                    Privacy Policy.
                  </li>
                </ul>
              </div>
              <h6>Scope of this Privacy Policy</h6>
              <p>
                3. This Privacy Policy applies only to the actions of Egghead
                Partners Limited and Users with respect to this Website. It does
                not extend to any websites that can be accessed from this
                Website including, but not limited to, any links we may provide
                to social media websites.
              </p>
              <p>
                4. For purposes of the applicable Data Protection Laws, Egghead
                Partners Limited is the "data controller". This means that
                Egghead Partners Limited determines the purposes for which, and
                the manner in which, your Data is processed.
              </p>
              <p>
                Do we process any sensitive personal information? We do not
                process sensitive personal information.
              </p>{" "}
              <h6>Data Collected</h6>
              <p>
                How do we process your information? We process your information
                to provide, improve, and administer our Services, communicate
                with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes
                with your consent. We process your information only when we have
                a valid legal reason to do so.
              </p>
              <p>
                5. We may collect the following Data, which includes personal
                Data, from you:
              </p>
              <div>
                <ul>
                  <li>name</li>
                  <li>date of birth;</li>
                  <li>
                    contact Information such as email addresses and telephone
                    numbers;
                  </li>
                  <li>identity proof;</li>
                  <li>profile picture;</li>
                </ul>
              </div>
              <p>in each case, in accordance with this Privacy Policy.</p>
              <h6>How We Collect Data</h6>
              <p>6. We collect Data in the following ways:</p>
              <div>
                <ul>
                  <li>data is given to use by you; and</li>
                  <li>data is collected automatically.</li>
                </ul>
              </div>
              <h6>Data That is Given to Us by You</h6>
              <p>
                7. Egghead Partners Limited will collect your Data in a number
                of ways, for example:
              </p>
              <div>
                <ul>
                  <li>
                    when you contact us through the Website, by telephone, post,
                    e-mail or through any other means;
                  </li>
                  <li>
                    when you register with us and set up an account to receive
                    our products/services;
                  </li>
                </ul>
              </div>
              <p>in each case, in accordance with this Privacy Policy.</p>
              <h6>Data That is Collected Automatically</h6>
              <p>
                8. To the extent that you access the Website, we will collect
                your Data automatically, for example:
              </p>
              <div>
                <ul>
                  <li>
                    We automatically collect some information about your visit
                    to the Website. This information helps us to make
                    improvements to Website content and navigation, and includes
                    your IP address, the date, times and frequency with which
                    you access the Website and the way you use and interact with
                    its content.
                  </li>
                </ul>
              </div>
              <h6>Our Use of Data</h6>
              <p>
                10. We may use your Data for the above purposes if we deem it
                necessary to do so for our legitimate interests. If you are not
                satisfied with this, you have the right to object in certain
                circumstances (see the section headed "Your rights" below).
              </p>
              <p>
                11. When you register with us and set up an account to receive
                our services, the legal basis for this processing is the
                performance of a contract between you and us and/or taking
                steps, at your request, to enter into such a contract.
              </p>
              <h6>Who We Share Data With</h6>
              <p>
                12. We may share your Data with the following groups of people
                for the following reasons:
              </p>
              <div>
                <ul>
                  <li>
                    Third party service providers who provide services to us
                    which require the processing of personal data - To perform
                    bio-metric checks;
                  </li>
                </ul>
              </div>
              <p>in each case, in accordance with this Privacy Policy.</p>
              <h6>Keeping Data Secure</h6>
              <p>
                13. We will use technical and organisational measures to
                safeguard your Data, for example:
              </p>
              <div>
                <ul>
                  <li>
                    Access to your account is controlled by a password and a
                    user name that is unique to you.
                  </li>
                  <li>We store your Data on secure servers.</li>
                </ul>
              </div>
              <p>
                14. Technical and organisational measures include measures to
                deal with any suspected data breach. If you suspect any misuse
                or loss or unauthorised access to your Data, please let us know
                immediately by contacting us via this e-mail address:
                info@eyedyll.com.
              </p>
              <p>
                15. If you want detailed information from Get Safe Online on how
                to protect your information and your computers and devices
                against fraud, identity theft, viruses and many other online
                problems, please visit www.getsafeonline.org. Get Safe Online is
                supported by HM Government and leading businesses.
              </p>
              <h6>Data Retention</h6>
              <p>
                16. Unless a longer retention period is required or permitted by
                law, we will only hold your Data on our systems for the period
                necessary to fulfil the purposes outlined in this Privacy Policy
                or until you request that the Data be deleted.
              </p>
              <p>
                17. Even if we delete your Data, it may persist on backup or
                archival media for legal, tax or regulatory purposes.
              </p>
              <h6>Your Rights</h6>
              <p>18. You have the following rights in relation to your Data:</p>
              <div>
                <ul>
                  <li>
                    Right to access - the right to request (i) copies of the
                    information we hold about you at any time, or (ii) that we
                    modify, update or delete such information. If we provide you
                    with access to the information we hold about you, we will
                    not charge you for this, unless your request is "manifestly
                    unfounded or excessive." Where we are legally permitted to
                    do so, we may refuse your request. If we refuse your
                    request, we will tell you the reasons why.
                  </li>
                  <li>
                    Right to correct - the right to have your Data rectified if
                    it is inaccurate or incomplete.
                  </li>
                  <li>
                    Right to erase - the right to request that we delete or
                    remove your Data from our systems.
                  </li>
                  <li>
                    Right to restrict our use of your Data - the right to
                    "block" us from using your Data or limit the way in which we
                    can use it.
                  </li>
                  <li>
                    Right to data portability - the right to request that we
                    move, copy or transfer your Data.
                  </li>
                  <li>
                    Right to object - the right to object to our use of your
                    Data including where we use it for our legitimate interests.
                  </li>
                </ul>
              </div>
              <p>
                19. To make enquiries, exercise any of your rights set out
                above, or withdraw your consent to the processing of your Data
                (where consent is our legal basis for processing your Data),
                please contact us via this e-mail address:{" "}
                <strong>info@eyedyll.com</strong>.
              </p>
              <p>
                20. If you are not satisfied with the way a complaint you make
                in relation to your Data is handled by us, you may be able to
                refer your complaint to the relevant data protection authority.
                For the UK, this is the Information Commissioner's Office (ICO).
                The ICO's contact details can be found on their website at
                <a href="https://ico.org.uk/" target="_blank">
                  {" "}
                  https://ico.org.uk/
                </a>
                .
              </p>
              <p>
                21. It is important that the Data we hold about you is accurate
                and current. Please keep us informed if your Data changes during
                the period for which we hold it.
              </p>
              <h6>Links to Other Websites</h6>
              <p>
                22. This Website may, from time to time, provide links to other
                websites. We have no control over such websites and are not
                responsible for the content of these websites. This Privacy
                Policy does not extend to your use of such websites. You are
                advised to read the Privacy Policy or statement of other
                websites prior to using them.
              </p>
              <h6>Changes of Business Ownership and Control</h6>
              <p>
                23. Egghead Partners Limited may, from time to time, expand or
                reduce our business and this may involve the sale and/or the
                transfer of control of all or part of Egghead Partners Limited.
                Data provided by Users will, where it is relevant to any part of
                our business so transferred, be transferred along with that part
                and the new owner or newly controlling party will, under the
                terms of this Privacy Policy, be permitted to use the Data for
                the purposes for which it was originally supplied to us.
              </p>
              <p>
                24. We may also disclose Data to a prospective purchaser of our
                business or any part of it.
              </p>
              <p>
                25. In the above instances, we will take steps with the aim of
                ensuring your privacy is protected.
              </p>
              <h6>General</h6>
              <p>
                26. You may not transfer any of your rights under this Privacy
                Policy to any other person. We may transfer our rights under
                this Privacy Policy where we reasonably believe your rights will
                not be affected.
              </p>
              <p>
                27. If any court or competent authority finds that any provision
                of this Privacy Policy (or part of any provision) is invalid,
                illegal or unenforceable, that provision or part-provision will,
                to the extent required, be deemed to be deleted, and the
                validity and enforceability of the other provisions of this
                Privacy Policy will not be affected.
              </p>
              <p>
                28. Unless otherwise agreed, no delay, act or omission by a
                party in exercising any right or remedy will be deemed a waiver
                of that, or any other, right or remedy.
              </p>
              <p>
                29. This Agreement will be governed by and interpreted according
                to the law of England and Wales. All disputes arising under the
                Agreement will be subject to the exclusive jurisdiction of the
                English and Welsh courts.
              </p>
              <h6>Changes to This Privacy Policy</h6>
              <p>
                30. Egghead Partners Limited reserves the right to change this
                Privacy Policy as we may deem necessary from time to time or as
                may be required by law. Any changes will be immediately posted
                on the Website and you are deemed to have accepted the terms of
                the Privacy Policy on your first use of the Website following
                the alterations. You may contact Egghead Partners Limited by
                email at <strong>info@eyedyll.com</strong>.
              </p>
              <h6>Attribution</h6>
              <p>31. This Privacy Policy was created 06 August 2024.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <Conatct /> */}
    </main>
  );
};

export default PrivacyPolicy;
