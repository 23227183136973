import GLightbox from "glightbox";
import AOS from "aos";
import "aos/dist/aos.css";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";

export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export function toggleScrolled() {
  const selectBody = document?.querySelector("body");
  const selectHeader = document?.querySelector("#header");
  if (
    !selectHeader?.classList?.contains("scroll-up-sticky") &&
    !selectHeader?.classList?.contains("sticky-top") &&
    !selectHeader?.classList?.contains("fixed-top")
  )
    return;
  window.scrollY > 100
    ? selectBody?.classList?.add("scrolled")
    : selectBody?.classList?.remove("scrolled");
}

export function mobileNavToogle() {
  const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
  document.querySelector("body").classList.toggle("mobile-nav-active");
  mobileNavToggleBtn.classList.toggle("bi-list");
  mobileNavToggleBtn.classList.toggle("bi-x");
}

export function hideMobileNavOnHashLinks() {
  document.querySelectorAll("#navmenu a").forEach((navmenu) => {
    navmenu.addEventListener("click", () => {
      if (document.querySelector(".mobile-nav-active")) {
        mobileNavToogle();
      }
    });
  });
}

export function toggleMobileNavDropdowns() {
  document.querySelectorAll(".navmenu .toggle-dropdown").forEach((navmenu) => {
    navmenu.addEventListener("click", function (e) {
      if (document.querySelector(".mobile-nav-active")) {
        e.preventDefault();
        this.parentNode.classList.toggle("active");
        this.parentNode.nextElementSibling.classList.toggle("dropdown-active");
        e.stopImmediatePropagation();
      }
    });
  });
}

export function removePreloader() {
  const preloader = document.querySelector("#preloader");
  if (preloader) {
    window.addEventListener("load", () => {
      preloader.remove();
    });
  }
}

export function toggleScrollTop() {
  let scrollTop = document.querySelector(".scroll-top");
  if (scrollTop) {
    window.scrollY > 100
      ? scrollTop.classList.add("active")
      : scrollTop.classList.remove("active");
  }
}

export function scrollTopOnClick() {
  let scrollTop = document.querySelector(".scroll-top");
  scrollTop?.addEventListener("click", (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
}

export function aosInit() {
  AOS.init({
    duration: 1000,
    easing: "ease-in-out",
    once: true,
    mirror: false,
  });
  AOS.refresh();
}

export function initGLightbox() {
  const glightbox = GLightbox({
    selector: ".glightbox",
  });
}

export function initSwiperClients() {
  document?.querySelectorAll(".clients-swiper")?.forEach(function (swiper) {
    new Swiper(swiper, {
      loop: true,
      speed: 600,
      autoplay: {
        delay: 5000,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 80,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 120,
        },
      },
    });
  });
}
export function initSwiperTestimonials() {
  document
    ?.querySelectorAll(".testimonials-swiper")
    ?.forEach(function (swiper) {
      new Swiper(swiper, {
        loop: true,
        speed: 600,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    });
}

export function initIsotopeLayout() {
  document.querySelectorAll(".isotope-layout").forEach(function (isotopeItem) {
    let layout = isotopeItem.getAttribute("data-layout") ?? "masonry";
    let filter = isotopeItem.getAttribute("data-default-filter") ?? "*";
    let sort = isotopeItem.getAttribute("data-sort") ?? "original-order";

    let initIsotope;
    imagesLoaded(isotopeItem.querySelector(".isotope-container"), function () {
      initIsotope = new Isotope(
        isotopeItem.querySelector(".isotope-container"),
        {
          itemSelector: ".isotope-item",
          layoutMode: layout,
          filter: filter,
          sortBy: sort,
        }
      );
    });

    isotopeItem
      .querySelectorAll(".isotope-filters li")
      .forEach(function (filters) {
        filters.addEventListener(
          "click",
          function () {
            isotopeItem
              .querySelector(".isotope-filters .filter-active")
              .classList.remove("filter-active");
            this.classList.add("filter-active");
            initIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            if (typeof aosInit === "function") {
              aosInit();
            }
          },
          false
        );
      });
  });
}
export function toggleFaq() {
  // Select all FAQ items
  document
    .querySelectorAll(".faq-item h3, .faq-item .faq-toggle")
    .forEach((faqItem) => {
      faqItem.addEventListener("click", () => {
        faqItem.parentNode.classList.toggle("faq-active");
      });
    });
}

// export function toggleFaq() {
//   document
//     .querySelectorAll(".faq-item h3, .faq-item .faq-toggle")
//     .forEach((faqItem) => {
//       faqItem.addEventListener("click", () => {
//         faqItem?.parentNode?.classList?.toggle("faq-active");
//       });
//     });
// }
// export function toggleFaq() {
//   const faqList = document.querySelector(".faq-item h3, .faq-item .faq-toggle");
//   if (!faqList) return; // Exit if there are no FAQs

//   faqList.addEventListener("click", (event) => {
//     const faqItem = event.target.closest(".faq-item");
//     if (!faqItem) return; // Exit if clicked element is not a FAQ item

//     faqItem.classList.toggle("faq-active");
//   });
// }

export function correctScrollingPosition() {
  window.addEventListener("load", function (e) {
    if (window.location.hash) {
      if (document.querySelector(window.location.hash)) {
        setTimeout(() => {
          let section = document.querySelector(window.location.hash);
          let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
          window.scrollTo({
            top: section.offsetTop - parseInt(scrollMarginTop),
            behavior: "smooth",
          });
        }, 100);
      }
    }
  });
}

export function navmenuScrollspy() {
  let navmenulinks = document.querySelectorAll(".navmenu a");

  function navmenuScrollspy() {
    navmenulinks.forEach((navmenulink) => {
      if (!navmenulink.hash) return;
      let section = document.querySelector(navmenulink.hash);
      if (!section) return;
      let position = window.scrollY + 200;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        document
          .querySelectorAll(".navmenu a.active")
          .forEach((link) => link.classList.remove("active"));
        navmenulink.classList.add("active");
      } else {
        navmenulink.classList.remove("active");
      }
    });
  }

  window.addEventListener("load", navmenuScrollspy);
  document.addEventListener("scroll", navmenuScrollspy);
}
