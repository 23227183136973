import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from "../Common/main.js";

emailjs.init(PUBLIC_KEY);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sentMessage, setSentMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSentMessage("");
    const { name, email, phone } = formData;
    if (!name || !email || !phone) {
      console.log("All fields are mandatory");
      return;
    }
    try {
      const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID, formData);

      if (result.text === "OK") {
        setSentMessage("Your message has been sent. Thank you!");
        setError("");
      } else {
        setError("Failed to send the message. Please try again later.");
      }
    } catch (error) {
      setError("Failed to send the message. Please try again later.");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
        setSentMessage("");
      }, 5000);
    }
  };
  return (
    <section id="contact" className="contact section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-12">
                <div className="container" data-aos="fade-up">
                  <h1 style={{ fontSize: "3rem", fontWeight: "700" }}>Get in Touch</h1>
                  <p>
                    Let’s Chat! Got a question, feedback, or just want to say
                    hi? Drop us a message, and we’ll be in touch shortly. We
                    can’t wait to hear from you!
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <form
                  className="php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onSubmit={handleSubmit}
                >
                  <div className="row gy-4">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name *"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email *"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        placeholder="Phone No. *"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-md-12 text-center">
                      {loading && (
                        <div className="d-block loading">Loading...</div>
                      )}
                      {error && (
                        <div className="d-block error-message">{error}</div>
                      )}
                      {sentMessage && (
                        <div className="d-block sent-message">
                          {sentMessage}
                        </div>
                      )}

                      <button
                        type="submit"
                        // disabled={loading}
                        disabled
                        // onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-telephone"></i>
                  <h3>Mobile</h3>
                  <p>+44 79669 54189</p>
                </div>
                {/* <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@eyedyll.com</p>
                </div> */}
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>Email</h3>
                  <p>info@eyedyll.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="200"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
