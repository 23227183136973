import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Body from "../Dashboard/Body";
// import Protected from "./Protected";

import NotFound from "../NotFound/NotFound";
import DashLayout from "./../Dashboard/DashLayout";
import PrivacyPolicy from "../Dashboard/PrivacyPolicy";
import About from "../Dashboard/About";
import TermsAndConditions from "../Dashboard/TermsAndConditions";
import Contact from "../Dashboard/Contact";

function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<NotFound />} />
        <Route
          path="/"
          element={
            <DashLayout>
              <Body />
            </DashLayout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <DashLayout>
              <PrivacyPolicy />
            </DashLayout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <DashLayout>
              <TermsAndConditions />
            </DashLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <DashLayout>
              <Contact />
            </DashLayout>
          }
        />
        {/* <Route
          path="/about-us"
          element={
            <DashLayout>
              <About />
            </DashLayout>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
