import "./App.css";
import "./assets/css/login.css";
import WebRoutes from "./Components/Routes/WebRoutes";

function App() {
  return (
    <>
      <WebRoutes />
    </>
  );
}

export default App;
