import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import ImgNotFound from "../../assets/img/clients/ImgNotFound.png";
const Clients = () => {
  const testimonials = [
    {
      name: "A1 Masala Mart",
      rating: 5,
      review:
        `"Eyedyll platform has revolutionised how we connect with our customers. Going from having no digital presence to becoming one of the best has been an incredible journey. We now feel more connected than ever."`,
    },
    {
      name: "Anonymous",
      rating: 5,
      review:
        `"Eyedyll analytics give me clear, real-time insights, and I can even run the store from my phone if I need to. If you’re a merchant looking to level up, I highly recommend giving Eyedyll a try—it’s made a huge difference for us!"`,
    },
  ];

  return (
    <section id="clients" className="clients section">
      <div className="container section-title" data-aos="fade-up">
        <h2>What Our Customers Say</h2>
        <p>
          We strive to provide the best value to our customers. Read some of the
          genuine reviews and feedback they've shared with us
        </p>
      </div>
      <div className="container" data-aos="fade-up">
        <div className="swiper-container">
          <div className="swiper clients-swiper">
            <div className="swiper-wrapper align-items-center">
              <Swiper
                breakpoints={{
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                }}
                // pagination={true}
                // navigation={true}
                // centeredSlides={true}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                // navigation={{
                //   el: ".swiper-pagination",
                //   type: "bullets",
                //   clickable: true,
                // }}
                modules={[Navigation]}
                className="mySwiper"
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="testimonial-item">
                      <div className="d-flex justify-content-center">
                        <div>
                          <h5>{testimonial.name}</h5>
                          <div className="stars d-flex justify-content-center">
                            {Array(testimonial.rating)
                              .fill()
                              .map((_, i) => (
                                <i key={i} className="bi bi-star-fill"></i>
                              ))}
                          </div>
                        </div>
                      </div>
                      <p>
                        {/* <i className="bi bi-quote quote-icon-left"></i> */}
                        <span>{testimonial.review}</span>
                        {/* <i className="bi bi-quote quote-icon-right"></i> */}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="swiper-button-next">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "1.8rem" }}
            >
              arrow_forward
            </span>
          </div>
          <div className="swiper-button-prev">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "1.8rem" }}
            >
              arrow_back
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
